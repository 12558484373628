'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }



$(document).ready(function () {
    var _ref;

    $('.carousel_how-works').slick({
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        centerMode: false,
        responsive: [(_ref = {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }, _defineProperty(_ref, 'breakpoint', 480), _defineProperty(_ref, 'settings', {
            slidesToShow: 1
        }), _ref)]
    });

    $('.carousel-advantages').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        centerMode: true,
        responsive: [{
            breakpoint: 1001,
            settings: {
                slidesToShow: 1
            }
        }]
    });

    $('.carousel_clients').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: false,
        centerMode: true

    });

    $("a[href^='#']").click(function () {
        var _href = $(this).attr("href");
        $("html, body").animate({ scrollTop: $(_href).offset().top + "px" });
        return false;
    });

    $('.subscription-form').submit(function (e) {
        e.preventDefault(); 

        var $form = $(this);

        $.ajax({
            type: "POST",
            url: "mailer/smart.php",
            data: $form.serialize()
        }).done(function () {
            $form.find("input").val("");
            $form.hide();
            $('.success').show();
            console.log('ok');


            $form.trigger('reset');
        });
    });

    $('.form_modal').submit(function (e) {
        e.preventDefault(); 

        var $form = $(this);

        $.ajax({
            type: "POST",
            url: "mailer/smart.php",
            data: $form.serialize()
        }).done(function () {
            $form.find("input").val("");
            $('#consultation').hide();
            $('#thanks').show();
            console.log('ok');
            $form.trigger('reset');
        });
    });

    function burgerMenu(selector) {
        var menu = $(selector);
        var button = menu.find('.burger-menu_button', '.burger-menu_lines');
        var links = menu.find('.burger-menu_link');
        var overlay = menu.find('.burger-menu_overlay');

        button.on('click', function (e) {
            e.preventDefault();
            toggleMenu();
        });

        links.on('click', function () {
            return toggleMenu();
        });
        overlay.on('click', function () {
            return toggleMenu();
        });

        function toggleMenu() {
            menu.toggleClass('burger-menu_active');

            if (menu.hasClass('burger-menu_active')) {
                $('body').css('overflow', 'hidden');
            } else {
                $('body').css('overflow', 'visible');
            }
        }
    }

    burgerMenu('.burger-menu');

    var buttonWriteToUs = document.querySelectorAll('.btn-write'),
        buttonOrder = document.querySelectorAll('.button-little'),
        modalConsultation = document.querySelectorAll('#consultation')[0],
        modalOrder = document.querySelectorAll('#order')[0],
        overlay = document.querySelectorAll('.overlay')[0],
        modalClose = document.querySelectorAll('.modal__close'),
        html = document.querySelectorAll('html')[0],
        modalThanks = document.querySelectorAll('#thanks')[0];

    var scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    buttonWriteToUs.forEach(function (item) {
        item.addEventListener('click', function () {
            overlay.style.display = "block";
            modalConsultation.style.display = "block";
            modalThanks.style.display = "none";
            document.body.classList.add('no-scroll');
            document.body.style.paddingRight = scrollBarWidth + 'px';
        });
    });

    buttonOrder.forEach(function (item) {
        item.addEventListener('click', function () {
            overlay.style.display = "block";
            modalOrder.style.display = "block";
            html.style.overflow = "hidden";
            modalThanks.style.display = "none";
        });
    });

    modalClose.forEach(function (item) {
        item.addEventListener('click', function () {
            overlay.style.display = "none";
            html.style.overflow = "visible";
            document.body.classList.remove('no-scroll');
            document.body.style.paddingRight = '0';
            console.log('111');
        });
    });

    overlay.addEventListener('click', function () {
        modalConsultation.style.display = 'none';
        overlay.style.display = 'none';
        html.style.overflow = "visible";
        document.body.classList.remove('no-scroll');
        document.body.style.paddingRight = '0';
    });

    modalConsultation.addEventListener('click', function (event) {
        event.stopPropagation();
    });

    modalThanks.addEventListener('click', function (event) {
        event.stopPropagation();
    });
});